import React, { useState } from "react";
import * as styles from "./PricingCards.module.scss";
import { plansData } from "../../../constants/plans";
import Arrow from "../../../images/right-arrow.png";
import Modal from "../../atoms/Modal/Modal";

export default function PricingCards({ toggle }) {
  const [showroomsModal, setShowroomsModal] = useState(false);
  const [micrositesModal, setMicrositesModal] = useState(false);
  const [coverpagesModal, setCoverpagesModal] = useState(false);
  const [personalprofileModal, setPersonalprofileModal] = useState(false);
  const [portalModal, setPortalModal] = useState(false);

  const handleFeatureClick = (modalType) => {
    if (modalType === "showroomsModal") {
      setShowroomsModal(true);
    }
    if (modalType === "micrositesModal") {
      setMicrositesModal(true);
    }
    if (modalType === "coverpagesModal") {
      setCoverpagesModal(true);
    }
    if (modalType === "personalprofileModal") {
      setPersonalprofileModal(true);
    }
    if (modalType === "portalModal") {
      setPortalModal(true);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.cards}>
          {plansData.map((item, i) => (
            <div
              key={i}
              className={`${styles.cardContainer} ${styles[item?.extraClass]}`}
            >
              <div
                style={{ background: item?.bgColor }}
                className={styles.card}
              >
                <div>
                  <h1 className={styles.title}>
                    {item.title}
                    {toggle === "Yearly" && item.title === "Pro" && (
                      <span className={styles.saveText}>Save $100</span>
                    )}
                    {toggle === "Yearly" && item.title === "Personal" && (
                      <span className={styles.saveText}> Save $30</span>
                    )}
                  </h1>
                  <h5 className={styles.price}>
                    $
                    {toggle === "Monthly"
                      ? item.regularPrice
                      : item.title === "Pro" && toggle === "Yearly"
                      ? 248
                      : item.title === "Personal" && toggle === "Yearly"
                      ? 78
                      : item.regularPrice}
                  </h5>

                  <h4
                    className={styles.subtitle}
                    dangerouslySetInnerHTML={{ __html: item.subtitle }}
                  ></h4>
                  <h4 className={styles.top}>Includes:</h4>
                  <span className={styles.license}>{item?.license}</span>
                  <ul>
                    {item.features.map((feature, index) => (
                      <li key={index}>
                        {feature.modal ? (
                          <div className={styles.buttonContainer}>
                            <p>{feature?.before}</p>
                            <button
                              onClick={() => handleFeatureClick(feature.modal)}
                            >
                              {feature?.button}
                            </button>
                            <p>{feature?.after}</p>
                          </div>
                        ) : (
                          <span>{feature?.text}</span>
                        )}
                        {feature?.modules && (
                          <p>
                            Modules:
                            <button
                              onClick={() => window.open("/library", "_blank")}
                            >
                               Library
                            </button>
                            ,{" "}
                            <button
                              onClick={() =>
                                window.open("/mpublisher", "_blank")
                              }
                            >
                              MPublisher
                            </button>
                          </p>
                        )}
                        {feature?.modules2 && (
                          <p>
                            +Modules:{" "}
                            <button
                              onClick={() => window.open("/wizr-x", "_blank")}
                            >
                              WiZR X
                            </button>
                            ,{" "}
                            <button
                              onClick={() =>
                                window.open("/mpublisher", "_blank")
                              }
                            >
                              MPublisher (beta)
                            </button>
                            ,{" "}
                          </p>
                        )}
                        {feature?.modules3 && (
                          <p>
                            {" "}
                            Multiuser intranet:{" "}
                            <button
                              onClick={() => window.open("/wizr-x", "_blank")}
                            >
                              WiZR X
                            </button>
                          </p>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  {i !== 0 && (
                    <p className={styles.trialText}>
                      We will not charge your card for the first 3 weeks.
                    </p>
                  )}
                  <button
                    onClick={() => window.open(`${item.toLink}`, "_blank")}
                    className={styles.pricingBtn}
                  >
                    {item.button} <img src={Arrow} alt="Arrow Icon" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {showroomsModal && (
        <Modal
          title="What is a Show Room?"
          description="A Show Room allows you to seamlessly create impactful presentations by customizing and combining 2-20 elements, such as documents, videos, and pictures."
          image="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1721816921/Templates/Screenshot_2024-07-24_at_12.26.17_rhgvjz.png"
          handleClose={() => setShowroomsModal(false)}
        ></Modal>
      )}
      {micrositesModal && (
        <Modal
          title="What is a Microsite?"
          description="Microsite is the combination of two or more Show rooms."
          image="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703278072/studio%20visuals%202/Frame_20500_yzh1if.png"
          handleClose={() => setMicrositesModal(false)}
        ></Modal>
      )}
      {coverpagesModal && (
        <Modal
          title="What is a Cover Page?"
          description="Cover pages are designed to merge multiple Show rooms into a single presentation, featuring a customized cover."
          image="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1727950329/Screenshot_2024-10-03_at_12.06.29_o1bgtg.png"
          handleClose={() => setCoverpagesModal(false)}
        ></Modal>
      )}
      {personalprofileModal && (
        <Modal
          title="What is a Personal Profile?"
          description="A personal profile is an individual portfolio, similar to a CV, where you can share your details and information with colleagues."
          image="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1712524624/Rectangle_15345_zvbqi4_jrj40s.png"
          handleClose={() => setPersonalprofileModal(false)}
        ></Modal>
      )}
      {portalModal && (
        <Modal
          title="What is a Portal?"
          description="A portal refers to a structure or container that organizes content, files, or resources."
          image="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704997353/Rectangle_15188_1_ff2qck.png"
          handleClose={() => setPortalModal(false)}
        ></Modal>
      )}
    </>
  );
}
